import React from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import './App.css';

import HomePage from './Page/HomePageScrollable.jsx';
import SobremiPage from './Page/SobremiPage.jsx';
import ContactPage from './Page/ContactPage.jsx';

function App() {
  return (
    <BrowserRouter className="App">
      <Routes>
        <Route path="/" element = {<HomePage />} />
          {/* <Route path="/terapeuta-familiar" element = {<HomePage />} />
          <Route path="/sindrome-alcoholico-fetal" element = {<HomePage />} />
          <Route path="/trastornos-conducta" element = {<HomePage />} />
          <Route path="/trastornos-conducta-alimentaria" element = {<HomePage />} />
          <Route path="/tratamiento-adicciones" element = {<HomePage />} /> */}
          <Route path="/sobre-mi" element = {<SobremiPage />} />
          <Route path="/contacto" element = {<ContactPage />} />
      </Routes>
    </BrowserRouter>   
  );
}

export default App;