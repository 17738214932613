import {Section,ServiciosTitle,BoxContainerWrapper,BoxContainer,BoxImage,BoxDescription,InvisibleButton} from '../assets/ServiciosStyle';
import { useEffect } from 'react';

  const Servicios = () => {

    // Función para cargar el script de SociableKit
  const loadScript = (src, id) => {
    if (!document.getElementById(id)) {
      const script = document.createElement('script');
      script.src = src;
      script.id = id;
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
    }
  };

  useEffect(() => {
    loadScript('https://widgets.sociablekit.com/google-reviews/widget.js', 'sociablekit-script');
  }, []);
  
      return (
        <>
          { /* SECTIONS */ }
          <Section >
            <ServiciosTitle>SERVICIOS</ServiciosTitle>
            
          </Section>
          <BoxContainerWrapper>
                <BoxContainer >
                    <BoxDescription>Ingresos en centros terapeuticos</BoxDescription>
                    {/* <BoxImage src="/media/EntrevistaRadioLibertad.jpg" alt="Creative Content" /> */}
                </BoxContainer>
                

                <BoxContainer>
                    <InvisibleButton  target="_blank" rel="noopener noreferrer" className="no-underline">
                    <BoxDescription>Terapeuta Familiar en Adicciones</BoxDescription>
                        {/* <BoxImage src="/media/fotoPdfMarato.png" alt="Creative Content"/> */}
                    </InvisibleButton>
                </BoxContainer>

                <a href="https://www.youtube.com/watch?v=cPBolYmZ7nA" target="_blank" rel="noopener noreferrer">
                    <BoxContainer>
                        <BoxDescription>Terapias Online</BoxDescription>
                        <BoxImage src="/media/EntrevistaYoutubeTerapeutaIntervencionista.png" alt="Creative Content"/>
                    </BoxContainer>
                </a>
                
            </BoxContainerWrapper>
            <Section >
            <ServiciosTitle>TESTIMONIOS</ServiciosTitle>
            <div class='sk-ww-google-reviews' data-embed-id='25431353'></div>
            {/* <script src='https://widgets.sociablekit.com/google-reviews/widget.js' async defer></script> */}

          </Section>
          </>

  )
  }; 
  
  export default Servicios;