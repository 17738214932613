import styled from 'styled-components';
import { Link as ScrollLink } from 'react-scroll';  // Optional: for smooth scrolling

const TableOfContentsWrapper = styled.div`
  position: fixed;
  top: 10rem;
  right: 20px;
  width: 200px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  border-radius: 8px;
  padding: 1rem;
  z-index: 1000;
  @media screen and (max-width: 768px) {
    position: static;
    width: 100%;
    box-shadow: none;
  }
`;

const TOCTitle = styled.h3`
  font-size: 1.25rem;
  color: #333;
  margin-bottom: 0.5rem;
`;

const TOCList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const TOCItem = styled.li`
  margin: 0.5rem 0;
`;

const TOCLink = styled(ScrollLink)`
  text-decoration: none;
  // color: #0073e6;
  color: #9e8f08;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;


export {TableOfContentsWrapper,TOCTitle,TOCList,TOCItem, TOCLink};

