import styled from 'styled-components';

const Section = styled.section`
  margin: 3rem 12rem;
  box-sizing: border-box;
  min-height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 768px) {
    margin: 0 1rem;
    margin-bottom: 6rem;
  }
`;

const ServiciosTitle = styled.h3`
  font-family: 'Roboto', sans-serif;
  font-size: 2.5rem;
  color: #2c3e50;
  background: linear-gradient(to right, #9e900b, #4d4606);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  
  @media (max-width: 768px) {
    font-size: 2rem;
    padding: 0 0.5rem;
  }

  @media (min-width: 769px) and (max-width: 1200px) {
    font-size: 2.25rem;
  }
`;

const BoxContainerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 2rem;
  margin-right: 2rem;
  margin-bottom: 4rem;

  @media screen and (max-width: 768px) {
    margin-bottom: 2rem;
  }
`;

const BoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1.5rem;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  cursor: pointer;
  width: 18rem;
  height: 18rem;

  @media screen and (max-width: 768px) {
    width: 80%;
    height: auto;
    margin-left: 1rem;
  }
`;

const BoxImage = styled.img`
  width: 80%;
  height: auto;
  border-radius: 8px;
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }
`;

const BoxDescription = styled.h2`
  padding: 5px;
  text-align: center;
  color: black;
  font-size: 1rem;
  margin-top: 1rem;
  text-decoration: none;
`;

const InvisibleButton = styled.button`
  border: none;
  padding: 0;
  background: none;
  cursor: pointer;
`;

// Tabla de Contenidos
const TableOfContentsWrapper = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 200px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  border-radius: 8px;
  padding: 1rem;
  z-index: 1000;
  
  @media screen and (max-width: 768px) {
    position: static;
    width: 100%;
    box-shadow: none;
    margin-top: 1rem;
  }
`;

const TOCTitle = styled.h3`
  font-size: 1.25rem;
  color: #333;
  margin-bottom: 0.5rem;
`;

const TOCList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const TOCItem = styled.li`
  margin: 0.5rem 0;
`;

const TOCLink = styled.a`
  text-decoration: none;
  color: #0073e6;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export {
  Section,
  ServiciosTitle,
  BoxContainerWrapper,
  BoxContainer,
  BoxImage,
  BoxDescription,
  InvisibleButton,
  TableOfContentsWrapper,
  TOCTitle,
  TOCList,
  TOCItem,
  TOCLink
};
