import styled from 'styled-components';

// Estilos globales
const Body = styled.body`
  margin: 0 2rem;
  padding: 1rem;
  font-family: "Poppins", sans-serif;

  @media screen and (max-width: 1200px) {
    margin: 0 1rem;
  }

  @media screen and (max-width: 768px) {
    margin: 0 0.5rem;
  }
`;

// Componentes de navegación
const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 15vh;
  margin-right: 5rem;
  @media screen and (max-width: 1200px) {
    display: none;
  }
`;

const NavLinks = styled.ul`
  display: flex;
  gap: 2rem;
  list-style: none;
  font-size: 1.5rem;
  
  a {
    color: #6F686D;
    list-style: none;
    text-decoration: none;
  }
  
  a:hover {
    color: #6F686D;
    text-decoration: underline;
    text-underline-offset: 0.5rem;
    text-decoration-color: #6F686D;    
  }

  @media screen and (max-width: 768px) {
    font-size: 1.2rem;
    gap: 1rem;
  }
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
  font-size: 2rem;
  font-weight: bold;
  cursor: pointer;
  margin-top: 7rem;
  margin-left: 2rem;

  img {
    max-width: 80%; /* La imagen no debe ser más ancha que su contenedor */
    height: auto; /* Para mantener la relación de aspecto */
  }

  @media screen and (max-width: 768px) {
    margin-left: -2rem;
    font-size: 1.5rem;
  }

  @media screen and (max-width: 480px) {
    font-size: 1.2rem;
    margin-left: -1rem;
  }
`;

const HamburgerNav = styled.nav`
  display: none;

  @media screen and (max-width: 1200px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 17vh;
    width: 100%; /* Para asegurar que ocupa todo el ancho disponible */
    padding: 0 1rem; /* Añade padding para asegurar distancia de los márgenes */
  }

  @media screen and (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const HamburgerMenu = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative; /* Para permitir el posicionamiento absoluto del menú */

  @media screen and (max-width: 768px) {
    margin-top : 4rem;
    justify-content: flex-end; /* Mantiene el menú a la derecha */
  }

  @media screen and (min-width: 1024px) {
    justify-content: flex-end; /* Mantiene el menú a la derecha */
  }
`;


const HamburgerIcon = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 24px;
  width: 30px;
  cursor: pointer;

  span {
    width: 100%;
    height: 2px;
    background-color: #6F686D;
    transition: all 0.3s ease-in-out;

    &:first-child {
      transform: ${({ isOpen }) => isOpen ? 'rotate(45deg) translate(10px,5px)' : 'none'};
    }

    &:nth-child(2) {
      opacity: ${({ isOpen }) => isOpen ? '0' : '1'};
    }

    &:last-child {
      transform: ${({ isOpen }) => isOpen ? 'rotate(-45deg) translate(10px,-5px)' : 'none'};
    }
  }
`;

const MenuLinks = styled.div`
  position: relative;
  top: 100%;
  right: 0;
  list-style: none;
  width: fit-content;
  max-height: ${({ isOpen }) => isOpen ? '400px' : '0'};
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  

  a {
    color: #6F686D;
    list-style: none;
    display: block;
    padding: 10px;
    text-align: center;
    font-size: 1.2rem;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
  }

  a:hover {
    color: #6F686D;
    text-decoration: underline;
    text-underline-offset: 0.5rem;
    text-decoration-color: rgb(181, 181, 181);
  }
a
  @media screen and (max-width: 768px) {
    font-size: 1rem;
    max-height: ${({ isOpen }) => isOpen ? '300px' : '0'}; /* Ajuste de altura para pantallas pequeñas */
  }

  @media screen and (max-width: 1024px) {
    right: 10%; /* Ajuste para tabletas */
  }
`;


export { Body, Nav, NavLinks, Logo, HamburgerNav, HamburgerMenu, HamburgerIcon, MenuLinks };



