
import {TableOfContentsWrapper,TOCTitle,TOCList,TOCItem, TOCLink} from '../assets/FamiliarContenidoStyle';

const TableOfContents = () => {
  return (
    <TableOfContentsWrapper>
      <TOCTitle>Índice</TOCTitle>
      <TOCList>
        <TOCItem>
          <TOCLink to="section1" smooth={true} duration={500}>¿Qué es la Terapia Familiar y Cómo Puede Beneficiar a Tu Familia?</TOCLink>
        </TOCItem>
        <TOCItem>
          <TOCLink to="section2" smooth={true} duration={500}>Los Beneficios de la Terapia Familiar: Comunicación, Resolución de Conflictos y Más</TOCLink>
        </TOCItem>
        <TOCItem>
          <TOCLink to="section3" smooth={true} duration={500}>Problemas Comunes que Aborda la Terapia Familiar          </TOCLink>
        </TOCItem>
        <TOCItem>
          <TOCLink to="section4" smooth={true} duration={500}>¿Cómo Funciona la Terapia Familiar? Proceso y Expectativas          </TOCLink>
        </TOCItem>
        <TOCItem>
          <TOCLink to="section5" smooth={true} duration={500}>Por Qué Elegir un Terapeuta Familiar Profesional y con Experiencia ?      </TOCLink>
        </TOCItem>
        <TOCItem>
          <TOCLink to="section6" smooth={true} duration={500}>Historias de Éxito y Testimonios de Familias que Han Experimentado la Terapia Familiar     </TOCLink>
        </TOCItem>
        <TOCItem>
          <TOCLink to="section6" smooth={true} duration={500}>Encuentra un Terapeuta Familiar en Tu Ciudad       </TOCLink>
        </TOCItem>
      </TOCList>
    </TableOfContentsWrapper>
  );
};

export default TableOfContents;