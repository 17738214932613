import styled from 'styled-components';

const Body = styled.body`
    margin-right:2;
    margin-left:2;
    padding: 1;
    font-family: "Poppins", sans-serif;
`;

export {Body};
// export {Body,MainContainer};
