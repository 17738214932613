
import {Section,SectionContainer,SectionPicContainer,
  SectionText,GreyTitle,Title,BtnContainer,GreySubtitle,Btn} from '../assets/PresentacionStyles';

const Navbar = () => {
  // const openPDFInNewTab = () => {
  //   const pdfUrl = require('../assets/CV2024-Víctorsánchez.pdf');
  //   window.open(pdfUrl);
  // };
  // const handleCVDownload = () => {
  //   const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  //   const cvURL = '/cv';

  //   if (isMobile) {
  //     window.location.href = cvURL;
  //   } else {
  //     window.open(cvURL, '_blank');
  //   }
  // };

    return (
      <>
        { /* SECTIONS */ }
        <Section id="profile">
          <SectionContainer className="section-container">
            <SectionPicContainer style={{marginTop: '9rem'}} className="section_pic-container">
              <img src="/media/JoseAngelFotodePerfil.png" alt="Jose Angel Terapeuta Intervencionista" />
            </SectionPicContainer>
            <SectionText className="section_text">
              <GreyTitle className="section_text_p2">Jose Angel Ruiz Perez</GreyTitle>
              <Title className="title">Terapeuta Familiar | Especialista en Trastornos de Conducta y Tratamiento de Adicciones</Title>
              <GreySubtitle className="section_text_p2">terapeutaintervencionista@gmail.com </GreySubtitle>
              <GreySubtitle className="section_text_p2"> 650 00 14 73 </GreySubtitle>

              <BtnContainer className="btn-container">
                {/* <Btn className="btn btn-color-2" >Descargar CV</Btn> */}
                <a href="/contacto"><Btn className="btn btn-color-2">Contáctame</Btn></a>
              </BtnContainer>
              {/* <SocialsContainer id="socials-container">
                <a href="https://es.linkedin.com/in/jose-a-ruiz-perez-81170ba6" target="_blank" rel="noopener noreferrer" >
                  <Icon src="/media/linkedin.png" alt="My Linkedin profile" className="icon" />
                </a>
              </SocialsContainer> */}
            </SectionText>
          </SectionContainer>
        </Section>
    </>
)
}; 

export default Navbar;