import styled from "styled-components";

const Section = styled.div`
`;

const Title = styled.h1`
  color:000000; 
  margin-top: 10rem;
  font-size: 3rem;
  text-align: center;
`;

const MainContent = styled.div`
  @media only screen and (max-width: 50rem) {
    align-items: center;     
  }
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start; 
`;

const StyledImage = styled.img`
  width:100%; /* Ancho del 100% del contenedor */
  max-width: 50px; /* Ancho máximo de 300px */
  height: auto; /* Altura automática para mantener la proporción */
  border-radius: 8px; /* Borde redondeado */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  margin-left: 2rem; 
`;

const Cajon = styled.div`
  display: flex; 
  align-items: center;
  justify-content: center; 
  margin-bottom: 5rem;
`;

const Form = styled.form`
  align-items : center;
  width: 500%;
  max-width: 400px;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  margin-left: 10rem;

  @media screen and (max-width: 768px) {
    margin: 0; 
    width: 80%;
  }
`;

const Label = styled.label`
  color : black; 
  display: block;
  margin-bottom: 0.5rem;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const SubmitButton = styled.input`
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  width:100%; 
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }
`;

const StyledParagraph = styled.p`
  margin-left: 5px; /* Margen izquierdo de 5px */
  color: white; /* Color blanco */
`;

export {Section,Title,MainContent,FlexContainer,StyledImage, Cajon, Form,Label,Input, TextArea, SubmitButton, StyledParagraph};