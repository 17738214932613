import React from 'react';

import {Body,Nav,NavLinks,Logo,HamburgerNav,HamburgerMenu,HamburgerIcon,MenuLinks} from '../assets/NavbarStyle';

const Navbar = () => {
    const [isOpen, setIsOpen] = React.useState(false);
  
    const toggleMenu = () => {
      setIsOpen(!isOpen);
    };

    return (
      <>
        <Body>
        { /* DESKTOP NAV */ }
          <Nav id="desktop-nav">
            <Logo>
              <a href="/">
              <img src="/media/Logo_Web_de_José_Ángel-removebg.png" alt="Jose Angel Terapeuta Logo" />
              </a>
            </Logo>
            <NavLinks>
              {/* <li><a href="#about" onClick={toggleMenu}>TEAF </a></li>
              <li><a href="#about" onClick={toggleMenu}>Trastornos de Conducta</a></li>
              <li><a href="#about" onClick={toggleMenu}>TCA</a></li>
              <li><a href="#about" onClick={toggleMenu}>Tratamiento de Adicciones</a></li> */}
              <li><a href="/sobre-mi">Sobre Mí</a></li>
              <li><a href="/contacto">Contacto</a></li>
            </NavLinks>
          </Nav>
          
        { /* HAMBURGER NAV */ }
        <HamburgerNav id="hamburger-nav">
            <Logo>
              <a href="/">
                <img src="/media/Logo_Web_de_José_Ángel-removebg.png" alt="Jose Angel Terapeuta Logo" />
              </a>
            </Logo>
          <HamburgerMenu>
            <HamburgerIcon isOpen={isOpen} onClick={toggleMenu}>
              <span></span>
              <span></span>
              <span></span>
              
            </HamburgerIcon>
            <MenuLinks isOpen={isOpen}>
              {/* <li><a href="#about" onClick={toggleMenu}>TEAF </a></li>
              <li><a href="#about" onClick={toggleMenu}>Trastornos de Conducta</a></li>
              <li><a href="#about" onClick={toggleMenu}>TCA</a></li>
              <li><a href="#about" onClick={toggleMenu}>Tratamiento de Adicciones</a></li> */}
              <li><a href="/sobre-mi" onClick={toggleMenu}>Sobre Mí</a></li>
              <li><a href="/contacto" onClick={toggleMenu}>Contacto</a></li>
            </MenuLinks>
          </HamburgerMenu>
        </HamburgerNav>
        </Body>
    </>
)
}; 

export default Navbar;
