import React, { useState } from 'react';
import ReactPlayer from 'react-player';

import Navbar from '../components/Navbar.jsx';

import {SectionPicContainer,Title,Icon } from '../assets/SobremiStyle.js';
import {Section,BoxContainerWrapper,BoxContainer,BoxImage,BoxDescription,TituloTv3, Popup,InvisibleButton,PlayButton} from '../assets/ProjectsPageStyle.js';

import { About,CenterText,Aboutmetext,StyledLink, AboutContainers, AboutDetailsContainer, AboutPic, DetailsContainer} from '../assets/SobremiStyle.js';

const Sobremi = () => {
  const [showPopup, setShowPopup] = useState(false);

    const openPDFInNewTab = () => {
    const pdfUrl = require('../assets/MARATO_1.pdf');
    window.open(pdfUrl);
    };

    const togglePopup = () => {
        setShowPopup(!showPopup);
    };

    return (
      <>
      { /* SECTION ABOUT */ }
      <About id="about">
        <Navbar/>
        <Title>Sobre Mí</Title>
        <CenterText>
        <Aboutmetext>Soy terapeuta especializado en acompañamiento familiar para trastornos de conducta, adicciones y acompañamiento en ingresos terapéuticos.
                    En esta web podrás ver algunos ejemplos en la sección de <StyledLink href="#proyectos">colaboraciones</StyledLink>,
                    Y si tienes alguna duda o te gustaría contactar conmigo en la página de <StyledLink href="#contacto">contacto</StyledLink>, puedes mandarme un correo automático.
        </Aboutmetext>
        </CenterText>
        <AboutContainers>
          <SectionPicContainer>
            <AboutPic src="/media/fotoJoseAngel_blancoynegro.jpg" alt="Profile picture" />
          </SectionPicContainer>
          <AboutDetailsContainer>
            <AboutContainers>
              <DetailsContainer>
                <Icon src="/media/experience.png" alt="Experience-icon"/>
                <h3>Experiencia</h3>
                <p>21 años<br/>Terapeuta experto en prevención en riesgos de exclusión social</p>
                <p>8 años 10 meses <br />Director de programas, Fundación <a href="https://proyectohombre.es/" target='_blank' rel="noreferrer">Proyecto Hombre</a></p>
                <p>2 años<br/> Terapeuta en la ONCE y otras asociaciones</p>
                <p>7 años<br/> Terapeuta en <a href="https://itasaludmental.com/" target='_blank' rel="noreferrer">ITA</a> Salud Mental</p>
                <p>4 años hasta la actualidad<br/> Intervencionista Familiar y Terapeuta en grupos de Autoayuda <a href="https://www.afasaf.org/afasaf-connecta" target='_blank' rel="noreferrer">Afasaf</a></p>
              </DetailsContainer>
              
            </AboutContainers>
          </AboutDetailsContainer>
        </AboutContainers>

        <Section id="proyectos" >

        <TituloTv3>Entrevista en TV3 : Sense ficció - Fills maltractadors, pares amb pors</TituloTv3>
                <ReactPlayer url={require('../assets/JoséÁngelenTV3SenseFicció.mp4')} width={'60%'} height={'90%'} controls volume='0.8'/> 
                <StyledLink href="https://www.ccma.cat/3cat/fills-maltractadors-pares-amb-pors/video/5971338/"  target="_blank" rel="noopener noreferrer">Documental completo
                </StyledLink>
            <BoxContainerWrapper>
                <BoxContainer onClick={togglePopup}>
                    <BoxImage src="/media/EntrevistaRadioLibertad.jpg" alt="Creative Content" />
                    <BoxDescription>Entrevista en Radio Libertad</BoxDescription>
                </BoxContainer>
                {showPopup && (
                    <Popup>
                        <button onClick={togglePopup} style={{marginLeft:'-15rem',background: 'none',border: 'none',color: 'white',cursor: 'pointer',fontSize: '20px',}}>
                            &times;
                        </button>
                    <ReactPlayer url={require('../assets/AudioEntrevistaRadio.mp3')} width={'300px'} controls volume='0.8' />
                    </Popup>
                )}

                <BoxContainer>
                    <InvisibleButton onClick={openPDFInNewTab} target="_blank" rel="noopener noreferrer" className="no-underline">
                        <BoxImage src="/media/fotoPdfMarato.png" alt="Creative Content"/>
                        <BoxDescription>Acompañamiento Àngel Ávila, corredor invidente que debutó corriendo la Maratón de Barcelona</BoxDescription>
                    </InvisibleButton>
                </BoxContainer>

                <PlayButton href="https://www.youtube.com/watch?v=cPBolYmZ7nA" target="_blank" rel="noopener noreferrer">
                    <BoxContainer>
                        <BoxImage src="/media/EntrevistaYoutubeTerapeutaIntervencionista.png" alt="Creative Content"/>
                        <BoxDescription>Entrevista Afasaf ConNecta_ Terapia Intervencionista con José Ángel Ruiz Pérez</BoxDescription>
                        <PlayButton href="https://www.youtube.com/watch?v=cPBolYmZ7nA" target="_blank" rel="noopener noreferrer">▶️</PlayButton>
                    </BoxContainer>
                </PlayButton>

                <PlayButton href="https://www.youtube.com/watch?v=WZ25PmKu7t4" target="_blank" rel="noopener noreferrer">
                    <BoxContainer>
                    <BoxImage src="/media/afasaf_experiencia_primerapersona.png" alt="Creative Content"/>
                    <BoxDescription>Afasaf ConNecta_Experiencia en Primera Persona del TEAF (trastornos del espectro alcohólico fetal)</BoxDescription>
                    </BoxContainer>
                </PlayButton>
            </BoxContainerWrapper>
            </Section>

      </About>
      </>
    );
  };
export default Sobremi;