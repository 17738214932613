import React from 'react';

import {Body} from '../assets/DesktopNavigation.js';

import Navbar from '../components/Navbar.jsx';
import Presentacion from '../components/Presentacion.jsx';
import Servicios from '../components/Servicios.jsx';
import TerapeutaFamiliarContenido from '../components/TerapeutaFamiliarContenido.jsx';

const HomePage = () => {
    return (
      <> 
        <Body>
        {/* <MainContainer> */}
    
        { /* HAMBURGER NAV */ }
          <Navbar/>

        { /* SECTIONS */ }
          <Presentacion/>
        
          <Servicios/>

          <TerapeutaFamiliarContenido/>
        { /* SECTION ABOUT */ }
          {/* <Sobremi/> */}

          {/* <Reseñas/> */}
          {/* <Contacto/> */}
          {/* </MainContainer> */}
          </Body>
      </>
    );
  };
export default HomePage;